<template>
  <div>
    <v-app-bar app :color="cabecera" dense elevate-on-scroll>
      <v-app-bar-nav-icon
        class="white--text"
        @click.stop="$emit('state')"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-btn dark @click="goHome()" text> INICIO </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-flex>
        <v-fab-transition>
          <v-btn
            v-show="cantidad > 0"
            @click="goDetalles()"
            absolute
            bottom
            right
            fab
          >
            <v-badge
              :content="cantidad"
              :value="cantidad"
              :color="botonCarro"
              overlap
              left
              bottom
            >
              <v-icon large dark> mdi-cart-variant </v-icon>
            </v-badge>
          </v-btn>
        </v-fab-transition>
      </v-flex>
    </v-app-bar>

    <v-card class="mx-auto" max-width="100%" tile>
      <v-img height="200" :src="imagen_cabecera"></v-img>
      <v-row style="margin: 1.5%; position: absolute; top: 70px">
        <v-list-item>
          <v-list-item-avatar size="130" class="white">
            <img class="pa-0" :src="logo_home" />
          </v-list-item-avatar>
        </v-list-item>
      </v-row>
    </v-card>

    <v-layout align-center justify-center class="mt-6 pt-1">
      <v-flex md10 sm12 xs12 offset-md2 class="mx-2">
        <v-card flat>
          <v-card-title class="py-0" style="font-size: 30px">
            {{ perfil.nombre }}

            <v-layout align-end justify-end>
              <v-btn
                icon
                :color="iconosRss"
                @click="linkMixin(perfil.instagram)"
              >
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn
                icon
                :color="iconosRss"
                @click="linkMixin(perfil.facebook)"
              >
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn icon :color="iconosRss" @click="wspMixin(perfil.whatsapp)">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </v-layout>
          </v-card-title>

          <v-card-actions>
            <v-btn text @click="mapMixin(perfil.ubicacion)">
              <v-icon>mdi-google-maps</v-icon>
              {{ perfil.direccion }}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              class="ml-6"
              dark
              x-small
              @click="verHorarios()"
              :color="typeColor(miHorario.estado)"
            >
              {{ horario }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: ["perfil"],
  computed: {
    ...mapState(["miHorario", "cantidad"]),
    horario() {
      return this.miHorario.estado == 0
        ? "Abierto"
        : this.miHorario.estado == 1
        ? "Ver Horarios"
        : this.miHorario.estado == 2
        ? "Cerrado"
        : "";
    },
  },
  methods: {
    ...mapMutations([
      "getPerfil",
      "delCarrito",
      "addHorarios",
      "setProductos",
      "setCategorias",
      "setTotaliza",
      "setCantidad",
    ]),
    goDetalles() {
      this.$router.push({ name: "detalles" }).catch(() => {});
    },
    typeColor(val) {
      if (val == 0) return "success";
      if (val == 1) return "info";
      if (val == 2) return "danger";
    },
    verHorarios() {
      if (this.miHorario.estado == 1) {
        this.$emit("state");
      }
    },
    goHome() {
      this.getPerfil([]);
      this.delCarrito([]);
      this.addHorarios([]);
      this.setProductos([]);
      this.setCategorias([]);
      this.setCantidad();
      this.setTotaliza();
      this.$router.push({ name: "home" }).catch(() => {});
    },
  },
};
</script>
